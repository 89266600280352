import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import React from 'react'
import { cn } from '~/lib/utils'

// Variants derived from https://ui.shadcn.com/docs/components/typography
type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'blockquote' | 'inline code' | 'lead' | 'large' | 'small' | 'muted'

const DEFAULT_VARIANT: Variant = 'p'

const typographyVariants = cva('', {
  variants: {
    variant: {
      'h1': 'scroll-m-20 text-3xl font-semibold tracking-tight lg:text-4xl',
      'h2': 'scroll-m-20 pb-2 text-2xl font-semibold tracking-tight first:mt-0',
      'h3': 'scroll-m-20 text-xl font-semibold tracking-tight',
      'h4': 'scroll-m-20 text-lg font-semibold tracking-tight',
      'p': 'leading-7 [&:not(:first-child)]:mt-6',
      'blockquote': 'mt-6 border-l-2 pl-6 italic',
      'inline code': 'relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold',
      'lead': 'text-xl',
      'large': 'text-lg font-semibold',
      'small': 'text-sm font-medium leading-none',
      'muted': 'text-sm text-muted-foreground',
    },
  },
  defaultVariants: {
    variant: DEFAULT_VARIANT,
  },
})

const VARIANT_TAG_MAP: Record<Variant, keyof React.JSX.IntrinsicElements> = {
  'h1': 'h1',
  'h2': 'h2',
  'h3': 'h3',
  'h4': 'h4',
  'p': 'p',
  'blockquote': 'blockquote',
  'inline code': 'code',
  'lead': 'p',
  'large': 'div',
  'small': 'small',
  'muted': 'p',
}

export interface TypographyProps extends VariantProps<typeof typographyVariants> {
  children: React.ReactNode
  asChild?: boolean
}

const Typography = ({ variant, asChild = false, ...props }: TypographyProps) => {
  const Tag: keyof React.JSX.IntrinsicElements = VARIANT_TAG_MAP[variant || DEFAULT_VARIANT]
  const Comp = asChild ? Slot : Tag
  return <Comp className={cn(typographyVariants({ variant }))} {...props} />
}
Typography.displayName = 'Typography'

export { Typography, typographyVariants }
